/**
 * The main layout component of the AMA POC application frontend.
 * Renders the chat interface and handles user interactions with the chatbot.
 */
import { useRef, useState, useEffect, useCallback } from "react";

import "../../index.css";

import styles from "./index.module.css";

import { chatApi, AskResponse, ChatRequest, LikeRequest, likeApi, InteractionType, ChatTimer, setLanguageApi, setInteraction } from "@/api";
import Messages from "@/components/Messages";
import { parseAnswerToText } from "@/components/Answer/AnswerParser";
import SelectLanguage from "@/components/SelectLanguage";
import { ControlBar } from "@/components/ControlBar";

import TabChat from "@/components/TabChat";

import { useSelector, useDispatch } from "react-redux";

import { hasTalkState, loadingStatus, toggleAvatar } from "@/components/Chat/chat";

import { RootState } from "@/store";

import { AzureSpeechServicesAdapter } from "@/adapters/AzureSpeechServices";
import { useTranslation } from "react-i18next";

import ChatHeader from "../ChatHeader";

import { TabProvider } from "../TabContext/index";

import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

import { VOICE_MAPPING } from "@/adapters/AzureSpeechServices";

let startTimer: number;
let conversationID: string = "";
let lastIterationID: string = "";

let lastAnswerData_points: string[] | null = null;
let lastAnswerThoughts: string[] | null = null;

const Chat = () => {
    const { t } = useTranslation();

    let timeTable: ChatTimer = { backend: 0, avatarTTS: 0, avatar: 0 };
    let errorMessage = t("settings.errorApi");

    const isLanguageSelected = useSelector((state: RootState) => state.chat.selectedValue);
    const isSoundActive = useSelector((state: RootState) => state.values.isSoundOn);

    let languageValue = null;
    if (isLanguageSelected && typeof isLanguageSelected.value === "string") {
        languageValue = isLanguageSelected.value;
    }

    const isAvatarOn = useSelector((state: RootState) => state.values.isAvatarOn);
    const talkState = useSelector((state: RootState) => state.values.talkState);
    const isFullScreen = useSelector((state: RootState) => state.chat.isFullScreen);
    const dispatch = useDispatch();

    const synthesizer = new AzureSpeechServicesAdapter(languageValue);
    const useSuggestFollowupQuestions = true;
    const lastQuestionRef = useRef<string>("");

    const [audioTag, setAudioTag] = useState<HTMLMediaElement>();

    const isAvatarOnRef = useRef<boolean>(isAvatarOn);
    isAvatarOnRef.current = isAvatarOn;

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>();
    const [question, setQuestion] = useState<string>("");
    const [lastResponse, setLastResponse] = useState<AskResponse>();
    const [streamedAnswer] = useState<string>("");
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const [avatarSynthesizer, setAvatarSynthesizer] = useState<SpeechSDK.SpeechSynthesizer | null>(null);

    // Function to get the voice based on the selected language
    const getVoiceForLanguage = (languageValue: string | null): string | undefined => {
        return languageValue ? VOICE_MAPPING[languageValue] : undefined;
    };
    const languageVoice = getVoiceForLanguage(languageValue);

    const handleResponse = (response: AskResponse) => {
        timeTable.backend = Date.now() - startTimer;
        conversationID = response.conversation_id;
        startTimer = Date.now();
        setLastResponse(response);
        if (isAvatarOn) {
            return isAvatarOnRef.current && renderAvatarAnimation(response);
        } else {
            if (isSoundActive) {
                return renderSoundResponse(response);
            }
        }
    };

    const buildAnswer = useCallback(
        (q: string, a: string): [user: string, response: AskResponse] => {
            const _lastResponse = {
                answer: a,
                conversation_id: conversationID,
                iteration_id: lastIterationID,
            };
            setLastResponse(_lastResponse);
            return [q, _lastResponse];
        },
        [setLastResponse]
    );

    /**
     * Updates the last response in the answers array with the provided answer,
     * thoughts and data points.
     * @param newAnswer - The new answer to update the last response with.
     * @returns void
     */
    const updateLastResponse = useCallback(
        (newAnswer: string): void => {
            let prevAnswers = [...answers];
            if (prevAnswers?.length === 0) return;

            let lastAnswer = prevAnswers[prevAnswers.length - 1];
            if (lastAnswer[1].answer === newAnswer) return;

            prevAnswers[prevAnswers.length - 1] = buildAnswer(lastAnswer[0], newAnswer);

            setAnswers(prevAnswers);

            // Reset global vars
            lastAnswerData_points = null;
            lastAnswerThoughts = null;
        },
        [answers, buildAnswer, setAnswers]
    );

    const makelikeAPIRequest = async (like: boolean, iteration_id: string) => {
        const request: LikeRequest = {
            conversation_id: conversationID,
            iteration_id: iteration_id,
            like: like,
            reason: ""
        };
        await likeApi(request);
    };

    const prepareRequestData = (question: string): ChatRequest => {
        const chatRequest: ChatRequest = {
            user_message: question
        };

        if (conversationID) chatRequest.conversation_id = conversationID;
        return chatRequest;
    };

    const handleApiError = (e: any, isAvatarOn: boolean) => {
        console.error("makeApiRequest Error:", e);
        if (isAvatarOn) {
            const errorResponse = {
                answer: errorMessage,
                conversation_id: conversationID,
                iteration_id: lastIterationID,
                thoughts: null,
                data_points: null
            };
            renderAvatarAnimation(errorResponse);
        }
        setError(new Error(String(errorMessage)));
    };

    const makeApiRequest = async (question: string, interactionType: InteractionType) => {
        setInteraction(interactionType);
        startTimer = Date.now();
        lastQuestionRef.current = question;
        if (error) setError(undefined);
        if (isAvatarOnRef.current) dispatch(hasTalkState("waitAnswer"));
        dispatch(loadingStatus(true));

        const requestData = prepareRequestData(question);
        try {
            // TODO: set this in config
            const isStreamDisabled = true;
            if (!isAvatarOn && !isSoundActive && !isStreamDisabled) {
                renderQuestion(lastQuestionRef.current);
            } else {
                const response = await chatApi(requestData);
                if (isAvatarOn) {
                    handleResponse(response);
                } else {
                    // only sound
                    renderResponse(response);
                    handleResponse(response);
                }
            }
        } catch (e) {
            handleApiError(e, isAvatarOnRef.current);
        } finally {
            if (!isAvatarOnRef.current) dispatch(loadingStatus(false));
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setAnswers([]);
        conversationID = "";
    };

    /**
     * Makes the avatar talk given a response object.
     * @param textAnswer The text to be spoken by the synthesizer.
     */
    const renderAvatarAnimation = async (response: AskResponse) => {
        renderResponse(response);
        const textAnswer = parseAnswerToText(response.chat_message);

        timeTable.avatarTTS = Date.now() - startTimer;
        startTimer = Date.now();
        avatarSynthesizer?.speakTextAsync(
            textAnswer,
            (result: SpeechSDK.SpeechSynthesisResult) => {
                if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                } else {
                }
            },
            (error: string) => {
                console.error("Error in speakSelectedText:", error);
                avatarSynthesizer?.close();
            }
        );
        return;
    };

    const renderSoundResponse = async (response: AskResponse) => {
        function handleNoAudio() {
            // When the audio fails, we just show the text answer
            renderResponse(response);
            dispatch(toggleAvatar());
        }
        const textAnswer = parseAnswerToText(response.chat_message);
        const result = await synthesizer.synthesizeText(textAnswer);
        if (!result.audioData) {
            handleNoAudio();
            return;
        }
        timeTable.avatarTTS = Date.now() - startTimer;
        startTimer = Date.now();

        const audioBlob = new Blob([result.audioData], { type: "audio/wav" });
        const audioFile = URL.createObjectURL(audioBlob);
        if (audioTag) {
            audioTag.src = audioFile;
        }
        return true;
    };

    /**
     * Displays a question in the component's state.
     */
    const renderQuestion = (question: string): void => {
        setAnswers([...answers, buildAnswer(question, "")]);
    };

    /**
     * Displays a response in the component's state.
     */
    const renderResponse = useCallback(
        (response: AskResponse | undefined): void => {
            dispatch(loadingStatus(false));
            response && setAnswers(prevAnswers => [...prevAnswers, [lastQuestionRef.current, response]]);
        },
        [dispatch]
    );

    useEffect(() => {
        const displayResponseWhileTalking = (talkState: string): void => {
            if (talkState !== "talk") return;
            renderResponse(lastResponse);
        };

        displayResponseWhileTalking(talkState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [talkState, lastResponse]);

    useEffect(() => {
        if (streamedAnswer && streamedAnswer !== "") {
            updateLastResponse(streamedAnswer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamedAnswer]);

    const isLoading = useSelector((state: RootState) => state.values.isLoading);

    const hasMessages = isLoading || streamedAnswer.length > 0 || answers.length > 0 || isRecording;

    return (
        <TabProvider>
            <div>
                <div className={`${styles.wrapper} ${isFullScreen && styles.fullscreen}`}>
                    <ChatHeader onClose={clearChat} />
                    <SelectLanguage setLanguageApi={setLanguageApi} />

                    {isLanguageSelected && (
                        <>
                            <ControlBar setAudioTag={setAudioTag} cleanChat={clearChat} />

                            <div id="chatContainer" className={styles.chatMainContainer}>
                                <div className={`${styles.chatRoot} ${isFullScreen && styles.fullscreen}`}>
                                    <Messages
                                        answers={answers}
                                        useSuggestFollowupQuestions={useSuggestFollowupQuestions}
                                        lastQuestionRef={lastQuestionRef}
                                        error={error}
                                        makeApiRequest={makeApiRequest}
                                        makelikeAPIRequest={makelikeAPIRequest}
                                        isRecording={isRecording}
                                    />
                                    <TabChat
                                        setIsRecording={setIsRecording}
                                        question={question}
                                        setQuestion={setQuestion}
                                        makeApiRequest={makeApiRequest}
                                        isLoading={isLoading}
                                        isRecording={isRecording}
                                        onClick={clearChat}
                                        disabled={answers.length === 0}
                                        sendQuestion={question => makeApiRequest(question, InteractionType.avatarAudioInput)}
                                        isFullScreen={isFullScreen}
                                        hasMessages={hasMessages}
                                        setAvatarSynthesizer={setAvatarSynthesizer}
                                        avatarSynthesizer={avatarSynthesizer}
                                        languageVoice={languageVoice}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </TabProvider>
    );
};

export default Chat;
