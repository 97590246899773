import { useState } from "react";
import { BaseAudioProps } from "@/components/AudioButton/types";
import { AzureSpeechServicesAdapter } from "@/adapters/AzureSpeechServices";

interface UseAudioRecorderProps extends BaseAudioProps {
    sendQuestion?: (state: string) => void;
}

const useAudioRecorder = ({ isRecording, setIsRecording, setQuestion, sendQuestion }: UseAudioRecorderProps) => {
    const [,setRecognizedText] = useState<string>("");

    const startRecording = (recognizer: AzureSpeechServicesAdapter) => {
        setIsRecording(true);

        recognizer.recognizeOnce(result => {
            setIsRecording(false);

            const recognizedText = result.text?.trim();
            if (recognizedText) {
                setRecognizedText(recognizedText);
                sendQuestion ? sendQuestion(recognizedText) : setQuestion(recognizedText);
            } else {
                console.error("SpeechRecognizer failed to recognize speech");
                alert("Não foi possível completar a gravação. Por favor, tente novamente mais tarde.");
            }
        });
    };

    return { startRecording };
};

export default useAudioRecorder;
