import React from "react";
import { AvatarOnSound } from "./AvatarOnSound";
import styles from "./index.module.css";
import ChangeLanguage from "@/components/ChangeLanguage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface Props {
    setAudioTag: (arg: HTMLAudioElement) => void;
    cleanChat: () => void;

}

export const ControlBar: React.FC<Props> = ({ setAudioTag, cleanChat }) => {
    const avatarSound = useSelector((state: RootState) => state.values.isSoundOn);

    const commonMediaProps = {
        playsInline: true,
        muted: !avatarSound,
        autoPlay: true
    };

    const isSoundOn = useSelector((state: RootState) => state.values.isSoundOn);
    return (
        <div className={styles.wrapper}>
            <AvatarOnSound />
            <ChangeLanguage cleanChat={cleanChat}/>
            {isSoundOn && (
                <audio
                    {...commonMediaProps}
                    ref={thisTalk => {
                        if (thisTalk) {
                            setAudioTag(thisTalk);
                        }
                    }}
                />
            )}
        </div>
    );
};
