import { AudioButtonSimpleProps } from "./types";
import useAudioRecorder from "@/hooks/useAudioRecorder";
import microphone from "@/assets/microphone.svg";
import styles from "./index.module.css";
import { useSelector } from "react-redux";

import { AzureSpeechServicesAdapter } from "@/adapters/AzureSpeechServices";
import { RootState } from "@/store";

import { useTranslation } from "react-i18next";

export const AudioButtonSimple: React.FC<AudioButtonSimpleProps> = ({ isRecording, setIsRecording, setQuestion, isChatPage }) => {
    const isLanguageSelected = useSelector((state: RootState) => state.chat.selectedValue);
    const { t } = useTranslation();

    let languageValue = null;
    if (isLanguageSelected && typeof isLanguageSelected.value === "string") {
        languageValue = isLanguageSelected.value;
    }

    const recognizer = new AzureSpeechServicesAdapter(languageValue);
    const { startRecording } = useAudioRecorder({ isRecording, setIsRecording, setQuestion });

    const hoverClass = isChatPage ? styles.noHover : ''; 

    return (
        <button
            className={`${styles.button} ${isRecording && styles.recording} ${hoverClass}`}
            aria-label={t("settings.acessability.microphone")}
            onClick={() => startRecording(recognizer)}
            disabled={isRecording}
        >
            <img src={microphone} alt={t("settings.microphone")} />
        </button>
    );
};
