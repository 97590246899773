import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import Chat from './src/components/Chat/index';
import store from "./src/store";
import ToggleChat from "./src/components/ToggleChat";

import createCache from "@emotion/cache"; 
import { CacheProvider } from "@emotion/react";


class ChatBot extends HTMLElement {
    private readonly shadowDOMRoot: ShadowRoot;
  
    constructor() {
      super();
  
      this.shadowDOMRoot = this.attachShadow({ mode: "closed" });
    }
  
    connectedCallback() {
      this.injectFonts();
      this.injectStylesheets();
      this.injectReactApp();
    }
    
    private injectReactApp(): void {
      const emotionStyle = document.createElement("style");
      this.shadowDOMRoot.appendChild(emotionStyle);
  
      const cache = createCache({
        container: emotionStyle,
        key: "customElementCache",
      });
        const root = ReactDOM.createRoot(this.shadowDOMRoot);
      root.render(
        <React.StrictMode>
          <CacheProvider value={cache}>
            <Provider store={store}>
              <ToggleChat>
                <Chat />
              </ToggleChat>
            </Provider>
          </CacheProvider>
        </React.StrictMode>
      );
    }

    private async injectStylesheets(): Promise<void> {

      const styleSheets =
        this.attributes
          .getNamedItem("styleSheets")
          ?.value.split(",")
          .map((s) => s.trim()) ?? [];
    
      styleSheets.forEach((href) => {
        const link = document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("href", href);
        this.shadowDOMRoot.appendChild(link);
      });
    }

    private injectFonts(): void {
      const fonts =
        this.attributes
          .getNamedItem("fonts")
          ?.value.split(",")
          .map((s) => s.trim()) ?? [];
  
      fonts.forEach((href) => {
        const linkNode = document.createElement("link");
        linkNode.type = "text/css";
        linkNode.rel = "stylesheet";
        linkNode.href = href;
        document.head.appendChild(linkNode);
      });
    }
  
  }
  
customElements.define('chat-bot', ChatBot)
