import { AudioButtonProps } from "./types";
import useAudioRecorder from "@/hooks/useAudioRecorder";
import microphone from "@/assets/microphone.svg";
import styles from "./index.module.css";
import { useSelector } from "react-redux";

import { AzureSpeechServicesAdapter } from "@/adapters/AzureSpeechServices";
import { RootState } from "@/store";

import { useTranslation } from "react-i18next";


export const AudioButton: React.FC<AudioButtonProps> = ({ isRecording, setIsRecording, setQuestion, sendQuestion }) => {
    const isLanguageSelected = useSelector((state: RootState) => state.chat.selectedValue);
    let languageValue = null;
    if (isLanguageSelected && typeof isLanguageSelected.value === "string") {
        languageValue = isLanguageSelected.value;
    }
    const { t } = useTranslation();
    const recognizer = new AzureSpeechServicesAdapter(languageValue);

    const { startRecording } = useAudioRecorder({ isRecording, setIsRecording, setQuestion, sendQuestion });

    return (
        <button
            className={`${styles.button} ${styles.buttonVideo} ${isRecording && styles.recording}`}
            onClick={() => startRecording(recognizer)}
            disabled={isRecording}
            aria-label={t("settings.acessability.microphone")}
        >
            <img src={microphone} alt={t("settings.microphone")} />
        </button>
    );
};
