import React, { useEffect } from "react";
// @ts-ignore
import * as Tabs from "@radix-ui/react-tabs";
import chat from "@/assets/chat.svg";
import chatActive from "@/assets/chatActive.svg";
import avatarChat from "@/assets/avatarChat.svg";
import avatarChatActive from "@/assets/avatarChatActive.svg";
import styles from "./index.module.css";
import { QuestionInput } from "@/components/QuestionInput";
import { ClearChatButton } from "@/components/ClearChatButton";
import { Avatar } from "@/components/Avatar";
import { useDispatch, useSelector } from "react-redux";

import { InteractionType } from "@/api";
import { isAvatarVisible } from "../Chat/chat";

import { useTranslation } from "react-i18next";
import { RootState } from "@/store";

import { useTabContext } from "../TabContext/index";

export enum TabNames {
    CHAT = "chat",
    SPEAK = "speak"
}

const FEATURE_AVATAR = import.meta.env.VITE_FEATURE_AVATAR;


type TabChatProps = {
    setIsRecording: (isRecording: boolean) => void;
    question: string;
    setQuestion: (question: string) => void;
    makeApiRequest: (question: string, interactionType: InteractionType) => void;
    isLoading: boolean;
    isRecording: boolean;
    disabled: boolean;
    onClick: () => void;
    sendQuestion: (state: string) => void;
    isFullScreen: boolean;
    hasMessages: boolean;
    setAvatarSynthesizer: any;
    avatarSynthesizer: any;
    languageVoice: any;
};

const TabChat: React.FC<TabChatProps> = ({
    setIsRecording,
    question,
    setQuestion,
    makeApiRequest,
    isRecording,
    disabled,
    onClick,
    sendQuestion,
    isFullScreen,
    hasMessages,
    setAvatarSynthesizer,
    avatarSynthesizer,
    languageVoice
}) => {
    const { activeTab, setActiveTab } = useTabContext();
    const chatIcon = activeTab === TabNames.CHAT ? chatActive : chat;
    const avatarIcon = activeTab === TabNames.SPEAK ? avatarChatActive : avatarChat;
    const talkState = useSelector((state: RootState) => state.values.talkState);
    const isLoading = useSelector((state: RootState) => state.values.isLoading);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const handleTabChange = (value: string) => {
        setActiveTab(value as TabNames);
    };

    useEffect(() => {
        activeTab === TabNames.SPEAK && dispatch(isAvatarVisible(true));
        activeTab === TabNames.CHAT && dispatch(isAvatarVisible(false));
    }, [activeTab, dispatch]);


    return (
        <Tabs.Root className={styles.container} value={activeTab} onValueChange={(value) => handleTabChange(value as TabNames)}>
            <Tabs.Content className={styles.TabsContent} value={TabNames.CHAT}>
                <ClearChatButton onClick={onClick} disabled={disabled} />

                <QuestionInput
                    clearOnSend
                    disabled={isLoading || talkState === "talk"}
                    setIsRecording={setIsRecording}
                    question={question}
                    setQuestion={setQuestion}
                    onSend={(question, audio_mode) => makeApiRequest(question, audio_mode ? InteractionType.chatRegular : InteractionType.chatRegular)}
                    isRecording={isRecording}
                    activeTab={TabNames.CHAT}
                    isChatPage={activeTab === TabNames.CHAT}
                />
            </Tabs.Content>
            <Tabs.Content className={styles.TabsContent} value={TabNames.SPEAK}>
                <Avatar
                    isLoading={isLoading}
                    isRecording={isRecording}
                    setIsRecording={setIsRecording}
                    setQuestion={setQuestion}
                    sendQuestion={sendQuestion}
                    isFullScreen={isFullScreen}
                    hasMessages = {hasMessages}
                    setAvatarSynthesizer= {setAvatarSynthesizer}
                    avatarSynthesizer={avatarSynthesizer}
                    activeTab={activeTab}
                    languageVoice={languageVoice}
                />
            </Tabs.Content>
            {FEATURE_AVATAR === 'true' && (
            <Tabs.List className={styles.buttons}>
                <Tabs.Trigger className={styles.button} value={TabNames.CHAT}>
                    <img src={chatIcon} width={24} height={24} aria-label={t("settings.acessability.chat_tab")} alt={t("settings.chat.tab")} />
                    {t("settings.chat.tab")}
                </Tabs.Trigger>
                <Tabs.Trigger className={styles.button} value={TabNames.SPEAK}>
                    <img src={avatarIcon} width={24} height={24} aria-label={t("settings.acessability.speak_tab")} alt={t("settings.video.tab")} />
                    {t("settings.video.tab")}
                </Tabs.Trigger>
            </Tabs.List>
        )}
        </Tabs.Root>
    );
};

export default TabChat;
