import styles from "./index.module.css";
import { AudioButton } from "../AudioButton";
import { SoundWaves } from "../SoundWaves";
import { LoadingWheel } from "../LoadingWheel";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

import React, { useState, useRef, useEffect } from "react";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { createAvatarSynthesizer, createWebRTCConnection } from "../AvatarMicrosoft/Utility";
import { avatarAppConfig } from "../AvatarMicrosoft/config";

import { TabNames } from "../TabChat/index";

interface Props {
    isLoading: boolean;
    isRecording: boolean;
    setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
    setQuestion: React.Dispatch<React.SetStateAction<string>>;
    sendQuestion: () => void;
    isFullScreen: boolean;
    hasMessages: boolean;
    setAvatarSynthesizer: any;
    avatarSynthesizer: any;
    activeTab: TabNames;
    peerConnection: RTCPeerConnection;
    languageVoice: any;
}

export const Avatar: React.FC<Props> = ({
    isLoading,
    isRecording,
    setIsRecording,
    setQuestion,
    sendQuestion,
    isFullScreen,
    hasMessages,
    setAvatarSynthesizer,
    avatarSynthesizer,
    activeTab,
    languageVoice
}) => {
    const myAvatarVideoEleRef = useRef<HTMLVideoElement | null>(null);
    const myAvatarAudioEleRef = useRef<HTMLAudioElement | null>(null);

    const [avatarLoading, setAvatarLoading] = useState<boolean>(true);

    const isSoundOn = useSelector((state: RootState) => state.values.isSoundOn);

    const { t } = useTranslation();

    const handleOnTrack = (event: RTCTrackEvent): void => {
        if (event.track.kind === "video") {
            const mediaPlayer = myAvatarVideoEleRef.current;
            if (mediaPlayer) {
                mediaPlayer.id = event.track.kind;
                mediaPlayer.srcObject = event.streams[0];
                mediaPlayer.autoplay = true;
                mediaPlayer.playsInline = true;
                mediaPlayer.addEventListener("play", () => {
                    window.requestAnimationFrame(() => {});
                });
            }
        } else {
            const audioPlayer = myAvatarAudioEleRef.current;
            if (audioPlayer) {
                audioPlayer.srcObject = event.streams[0];
                audioPlayer.autoplay = true;
            }
        }
    };

    const startSession = (): void => {
        var myHeaders = new Headers();
        myHeaders.append("Ocp-Apim-Subscription-Key", avatarAppConfig.cogSvcSubKey);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`https://${avatarAppConfig.cogSvcRegion}.tts.speech.microsoft.com/cognitiveservices/avatar/relay/token/v1`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // Extract values for ICE Server
                const iceUrl = result.Urls[0]; // Assuming the first URL is used
                const iceUsername = result.Username;
                const iceCredential = result.Password;

                // Call setupWebRTC with the extracted values
                const peerConnection = createWebRTCConnection(iceUrl, iceUsername, iceCredential);
                peerConnection.ontrack = handleOnTrack;
                peerConnection.addTransceiver("video", { direction: "sendrecv" });
                peerConnection.addTransceiver("audio", { direction: "sendrecv" });

                const synthesizer = createAvatarSynthesizer(iceUsername, iceCredential, languageVoice);
                setAvatarSynthesizer(synthesizer as unknown as SpeechSDK.SpeechSynthesizer);

                peerConnection.oniceconnectionstatechange = () => {
                    if (peerConnection.iceConnectionState === "connected") {
                    }
                    if (peerConnection.iceConnectionState === "disconnected" || peerConnection.iceConnectionState === "failed") {
                    }
                };

                synthesizer
                    .startAvatarAsync(peerConnection)
                    .then(() => {
                        setAvatarLoading(false);
                    })
                    .catch((error: Error) => {
                        console.error("[" + new Date().toISOString() + "] Avatar failed to start. Error:", error);
                    });
            })
            .catch(error => {
                console.error("Error fetching relay token:", error);
            });
    };

    // Effect used to Start Session (Generate Avatar)
    useEffect(() => {
        if (activeTab === TabNames.SPEAK) {
            startSession();
        }
    }, [activeTab, languageVoice]);

    return (
        <div className={`${styles.container} ${isFullScreen ? styles.fullScreen : ""} ${hasMessages ? styles.small : styles.large}`}>
            <div className={`${styles.AvatarFrame} ${styles.video}`}>
                {avatarLoading && <div className={styles.LoadingSpinner}></div>}
                <video className={styles.AvatarVideo} ref={myAvatarVideoEleRef} autoPlay playsInline muted={!isSoundOn}></video>
                <audio ref={myAvatarAudioEleRef} muted={!isSoundOn}></audio>

                <div className={styles.feedback}>
                    <span className={styles.label}>
                        {isLoading
                            ? t("settings.video.loading")
                            : isRecording
                            ? `${t("settings.video.label")} ${t("settings.video.active")}`
                            : `${t("settings.video.label")} ${t("settings.video.disabled")}`}
                    </span>
                    <div className={styles.animation}>{isRecording ? <SoundWaves /> : isLoading ? <LoadingWheel /> : null}</div>
                </div>
            </div>

            {/* Control buttons */}
            {/* <div className="chatButtonGroup"> */}
            {/* <div className={styles.chatButtonGroup}>
                <button className="btn btn-primary" onClick={startSession}>
                    Start Avatar
                </button>
                <button className="btn btn-secondary" onClick={stopSession}>
                    Stop Avatar
                </button>
            </div>
             */}
            <div className={styles.audio}>
                {/* <div className={styles.text}> */}
                <h5 className={styles.title}>{t("settings.video.title")}</h5>
                {/* <p className={styles.text}>{t("settings.video.description")}</p> */}
                {/* </div> */}
                <AudioButton isRecording={isRecording} setIsRecording={setIsRecording} setQuestion={setQuestion} sendQuestion={sendQuestion} />
            </div>
        </div>
    );
};
