import styles from "./index.module.css";
import user from "@/assets/user.svg";

interface Props {
    message: string;
}

export const Question = ({ message }: Props) => {
    return (
        <div className={styles.container} aria-live="polite" data-testid="questionContainer">
            <span className={styles.message} data-testid="questionMessage">
                {message}
            </span>
            <figure className={styles.avatar}>
                <img src={user} alt="User" />
            </figure>
        </div>
    );
};
