// ChatHeader.tsx
import React from "react";
import { useDispatch } from "react-redux";
import { miniChat, closeChat, toggleSize } from "./chatSlice";
import { Minimize, Close, FullScreen } from "./icons";
import styles from "./index.module.css";

import { useTranslation } from "react-i18next";

import { setSoundOff } from "../Chat/chat";

interface ChatHeaderProps {
    onClose: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ onClose }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const handleClose = () => {

        i18n.changeLanguage('pt')
        dispatch(closeChat());
        onClose();
    };

    const handleMinimize = () => {
        
        dispatch(miniChat());
        dispatch(setSoundOff(false));
    };

    return (
        <div className={styles.header}>
            <h6 className={styles.title}>{t("settings.title")}</h6>
            <div className={styles.buttons}>
                <div className={styles.fullScreenWrapper}> 
                    <button className={styles.button} onClick={() => dispatch(toggleSize())} aria-label={t("settings.acessability.maximize")}>
                        <FullScreen />
                    </button>
                </div>
                <button className={styles.button} onClick={handleMinimize} aria-label={t("settings.acessability.minimize")}>
                    <Minimize />
                </button>
                <button className={styles.button} onClick={handleClose} aria-label={t("settings.acessability.close")}>
                    <Close />
                </button>
            </div>
        </div>
    );
};

export default ChatHeader;
