import React, { useEffect, useState, useRef } from "react";

import styles from "./index.module.css";
import { Question } from "@/components/Question";
import { Answer } from "@/components/Answer";
import { AskResponse, InteractionType } from "@/api";
import { useTranslation } from "react-i18next";
import AnswerBox from "@/components/Answer/AnswerBox";

import { useSelector } from "react-redux";
import { RootState } from "@/store";

import { useTabContext } from "../TabContext/index";
import { TabNames } from "../TabChat/index";

interface MessagesProps {
    answers: [user: string, response: AskResponse][];
    useSuggestFollowupQuestions: boolean;
    lastQuestionRef: React.RefObject<any>;
    error?: Error | undefined;
    makeApiRequest: (query: string, type: InteractionType) => Promise<void>;
    makelikeAPIRequest: (like: boolean, iteration_id: string) => void;
    isRecording: boolean;
}

const isAnswerNotEmpty = (a: [user: string, response: AskResponse]): boolean => a[1].answer !== "";

const Messages: React.FC<MessagesProps> = ({ answers, lastQuestionRef, error, makeApiRequest, makelikeAPIRequest, isRecording }) => {
    const isLoading = useSelector((state: RootState) => state.values.isLoading);
    const { t } = useTranslation();
    const { activeTab } = useTabContext();

    const [dots, setDots] = useState(".");

    const prevAnswersLengthRef = useRef<number>(answers.length);

    const messageContainerRef = useRef<HTMLDivElement | null>(null);
    const prevQuestionRef = useRef<string>("");

    useEffect(() => {
        const answersLength = answers.length > prevAnswersLengthRef.current;
        const questionChange = lastQuestionRef.current !== prevQuestionRef.current;

        if ((answersLength || questionChange) && activeTab === TabNames.CHAT) {
            if (messageContainerRef.current) {
                messageContainerRef.current!.scrollTop = messageContainerRef.current!.scrollHeight;
                prevQuestionRef.current = lastQuestionRef.current;
            }
        }

        // Handle the animation (..) when recording
        if (isRecording) {
            const interval = setInterval(() => {
                setDots(prev => {
                    if (prev.length < 3) {
                        return prev + ".";
                    }
                    return ".";
                });
            }, 500);

            return () => clearInterval(interval);
        }
    }, [activeTab, lastQuestionRef, answers, isRecording]);

    return (
        <div
            className={`${styles.chatMessageStream} ${activeTab === TabNames.SPEAK && !lastQuestionRef.current ? styles.hideScroll : ""}`}
            id="messageContainer"
            ref={messageContainerRef}
        >
            {!lastQuestionRef.current && activeTab === TabNames.CHAT && (
                <AnswerBox sanitizedAnswerHtml={t("chat.message")} isStreaming={false} showAvatar={true} disablePadding={false} />
            )}

            {/* Show all messages in the CHAT tab */}
            {activeTab === TabNames.CHAT &&
                answers.filter(isAnswerNotEmpty).map((answer, index) => (
                    <div key={index}>
                        <Question message={answer[0]} />
                        <Answer key={index} answer={answer[1]} likeCall={makelikeAPIRequest} />
                    </div>
                ))}

            {activeTab === TabNames.SPEAK &&
                !isLoading &&
                !isRecording &&
                answers
                    .filter(isAnswerNotEmpty)
                    .slice(-1)
                    .map((answer, index) => (
                        <div key={index}>
                            <Question message={answer[0]} />
                        </div>
                    ))}

            {activeTab === TabNames.SPEAK && isLoading && (
                <div>
                    <Question message={lastQuestionRef.current} />
                </div>
            )}

            {activeTab === TabNames.SPEAK && isRecording && (
                <div>
                    <Question message={dots} />
                </div>
            )}

            {activeTab === TabNames.CHAT && isLoading && (
                <>
                    <Question message={lastQuestionRef.current} />
                    <AnswerBox sanitizedAnswerHtml="" isStreaming={true} showAvatar={true} disablePadding={false} />
                </>
            )}

            {error && !isLoading && (
                <>
                    <Question message={lastQuestionRef.current} />
                    <AnswerBox sanitizedAnswerHtml="" isStreaming={true} showAvatar={true} disablePadding={false} error={error.toString()} />
                </>
            )}
        </div>
    );
};

export default Messages;
