import React, { useMemo, useState, useCallback } from "react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { AskResponse } from "../../api";
import AnswerBox from "./AnswerBox";
import { parseAnswerToHtml } from "./AnswerParser";
import FeedbackButtons from "./FeedbackButtons";

import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface Props {
    answer: AskResponse;
    likeCall: (like: boolean, iteration_id: string) => void;
}

export const Answer = React.memo(({ answer, likeCall }: Props) => {
    const [like, setLike] = useState<boolean | null>(null);
    const isStreaming = useSelector((state: RootState) => state.values.isStreaming);

    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.chat_message), [answer]);

    const sanitizedAnswerHtml = useMemo(() => DOMPurify.sanitize(parsedAnswer.answerHtml, { ADD_ATTR: ["target"] }), [parsedAnswer]);

    const onLike = useCallback(() => {
        setLike(true);
        likeCall(true, answer.iteration_id);
    }, [likeCall, answer.iteration_id]);

    const onDislike = useCallback(() => {
        setLike(false);
        likeCall(false, answer.iteration_id);
    }, [likeCall, answer.iteration_id]);

    return (
        <div className={styles.container}>
            <div className={`${styles.answer}`}>
                <AnswerBox sanitizedAnswerHtml={sanitizedAnswerHtml} isStreaming={isStreaming} showAvatar={true} disablePadding={true} />
                {!isStreaming && (
                    <div id="answerFeedback" className={styles.answerFeedback}>
                        <FeedbackButtons like={like} onLike={onLike} onDislike={onDislike} />
                    </div>
                )}
            </div>
        </div>
    );
});
