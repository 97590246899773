import { AskResponse, ChatRequest, LikeRequest } from "./models";
import i18n from "@/i18n/config";

const SERVER_IP = import.meta.env.PROD ? import.meta.env.VITE_API_SERVER_IP_PROD : import.meta.env.VITE_API_SERVER_IP_DEV;
const API_CHAT_ENDPOINT = import.meta.env.VITE_API_CHAT_ENDPOINT;

let currentLanguageCode = "pt";
let currentInteraction = "manual";

export async function setLanguageApi(languageCode: string): Promise<null> {
    currentLanguageCode = languageCode;
    i18n.changeLanguage(languageCode);
    return null;
}

export async function setInteraction(interactionCode: string): Promise<null> {
    currentInteraction = interactionCode;
    return null;
}

function getHeaders() {
    return {
        "Content-Type": "application/json"
    };
}

const SUCCESS_STATUS_RANGE = 299;
const UNKNOWN_ERROR = "Unknown error";

async function fetchApi(endpoint: string, body: object): Promise<any> {
    const response = await fetch(`${SERVER_IP}/${endpoint}`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body)
    });

    const parsedResponse = await response.json();
    if (response.status > SUCCESS_STATUS_RANGE || !response.ok) {
        throw Error(parsedResponse.error || UNKNOWN_ERROR);
    }
    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    options.language = currentLanguageCode;
    options.input_type = currentInteraction;
    return fetchApi(API_CHAT_ENDPOINT, options);
}

export async function likeApi(options: LikeRequest): Promise<null> {
    return fetchApi("like", {
        conversation_id: options.conversation_id,
        iteration_id: options.iteration_id,
        like: options.like,
        reason: options.reason
    });
}
