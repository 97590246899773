
const LANGUAGES = [
    { value: "pt-PT", label: "Português (Portugal)" },
    { value: "pt-BR", label: "Português (Brasil)" },
    { value: "en-US", label: "English" },
    { value: "es-ES", label: "Español" },
    { value: "fr-FR", label: "Français" },
    { value: "de-DE", label: "Deutsch" },
    { value: "it-IT", label: "Italiano" },
    { value: "bn-IN", label: "বাংলা (Bengali)" },
    { value: "hi-IN", label: "हिंदी (Hindi)" },
    { value: "zh-CN", label: "中文 (Mandarim)" },
    { value: "ne-NP", label: "नेपाली (Nepalês)" },
    { value: "ru-RU", label: "Русский (Russo)" },
    { value: "uk-UA", label: "Українська (Ucraniano)" },
];

export default LANGUAGES;
