import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "@/components/ChatHeader/chatSlice";
import chatValues from "@/components/Chat/chat";

const store = configureStore({
    reducer: {
        chat: chatReducer,
        values: chatValues
    }
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
