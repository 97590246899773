import React from "react";
import { ThumbDislike24Regular, ThumbLike24Regular, ThumbDislike24Filled, ThumbLike24Filled } from "@fluentui/react-icons";
import styles from "./Answer.module.css";

interface FeedbackButtonsProps {
    like: boolean | null;
    onLike: () => void;
    onDislike: () => void;
}

const FeedbackButtons: React.FC<FeedbackButtonsProps> = ({ like, onLike, onDislike }) => (
    <>
        <button className={styles.likeDislikeButton} onClick={onDislike} tabIndex={1}>
            {like || like == null ? <ThumbDislike24Regular primaryFill="grey" /> : <ThumbDislike24Filled primaryFill="red" />}
        </button>
        <button className={styles.likeDislikeButton} onClick={onLike} tabIndex={1}>
            {!like || like == null ? <ThumbLike24Regular primaryFill="gray" /> : <ThumbLike24Filled primaryFill="#034AD8" />}
        </button>
    </>
);

export default FeedbackButtons;
