import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleChat, miniChat } from "@/components/ChatHeader/chatSlice";
import arrow from "@/assets/arrow.svg"; 
import buttonClose from "@/assets/avatar.svg";

import { RootState } from "@/store";

import styles from "./index.module.css";

import { useTranslation } from "react-i18next";

interface ToggleChatProps {
    children: React.ReactNode;
}

const ToggleChat: FC<ToggleChatProps> = ({ children }) => {
    const { isOpen, isMini, isClose } = useSelector((state: RootState) => state.chat);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const buttonClasses = [styles.button, isOpen ? styles.open : "", isMini ? styles.mini : ""].join(" ");
    const childrenClasses = [styles.children, isOpen ? styles.open : ""].join(" ");

    return (
        <div className={styles.wrapper}>
            {isClose || !isOpen ? (
                <button onClick={() => dispatch(toggleChat())} className={styles.button} tabIndex={1}>
                    <img className={styles.avatar_img_tgl} src={buttonClose} aria-label={t("settings.acessability.open")} />
                </button>
            ) : (
                <button onClick={() => dispatch(miniChat())} className={buttonClasses} tabIndex={1}>
                    <img src={arrow} aria-label={t("settings.acessability.minimize")} />
                </button>
            )}
            {!isClose && (
                <div className={childrenClasses}>
                    {children}
                </div>
            )}
        </div>
    );
};

export default ToggleChat;
