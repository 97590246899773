import { FC, useCallback } from "react";
import soundOff from "@/assets/soundOff.svg";
import soundOn from "@/assets/soundOn.svg";

import styles from "./AvatarOnSound.module.css";

import { useDispatch, useSelector } from "react-redux";
import { toggleAudio } from "@/components/Chat/chat";

import { useTranslation } from "react-i18next";
import { RootState } from "@/store";

export const AvatarOnSound: FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleButtonClick = useCallback(() => {
        dispatch(toggleAudio());
    }, [dispatch]);

    const avatarSound = useSelector((state: RootState) => state.values.isSoundOn);

    const btnClass = `${styles.button} ${avatarSound ? styles.active : ""}`;
    const imageSrc = avatarSound ? soundOn : soundOff;
    const buttonText = avatarSound ? t("settings.disable") : t("settings.active");
    const soundLabel = avatarSound ? t("settings.acessability.sound_disable") : t("settings.acessability.sound_active");


    return (
        <button className={btnClass} onClick={handleButtonClick} aria-pressed={avatarSound} aria-label={soundLabel}>
            <img src={imageSrc} alt={buttonText} />
            <span>{buttonText}</span>
        </button>
    );
};
