import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './locales/en.json';
import ptPT from './locales/pt.json';
import bnIN from './locales/bn.json';
import deDE from './locales/de.json';
import esES from './locales/es.json';
import frFR from './locales/fr.json';
import hiIN from './locales/hi.json';
import itIT from './locales/it.json';
import neNP from './locales/ne.json';
import ruRU from './locales/ru.json';
import ukUA from './locales/uk.json';
import urIN from './locales/ur.json';
import zhCN from './locales/zh.json';


const defaultLanguage = 'pt-PT';

export const defaultNamespace = 'default';

export const resources = {
    'en-US': {
        [defaultNamespace]: enUS,
    },
    'pt-PT': {
        [defaultNamespace]: ptPT,
    },
    'hi-IN': {
        [defaultNamespace]: hiIN,
    },
    'zh-CN': {
        [defaultNamespace]: zhCN,
    },
    'bn-IN': {
        [defaultNamespace]: bnIN,
    },
    'de-DE': {
        [defaultNamespace]: deDE,
    },
    'es-ES': {
        [defaultNamespace]: esES,
    },
    'fr-FR': {
        [defaultNamespace]: frFR,
    },
    'it-IT': {
        [defaultNamespace]: itIT,
    },
    'ne-NP': {
        [defaultNamespace]: neNP,
    },
    'ru-RU': {
        [defaultNamespace]: ruRU,
    },
    'uk-UA': {
        [defaultNamespace]: ukUA,
    },
    'ur-IN': {
        [defaultNamespace]: urIN,
    },
}

i18n.use(initReactI18next).init({
    defaultNS: defaultNamespace,
    ns: [defaultNamespace],
    resources,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
