
const MICROSOFT_SPEECH_API_KEY = import.meta.env.VITE_MICROSOFT_SPEECH_API_KEY;

  export const avatarAppConfig = {
    cogSvcRegion : "westeurope",
    cogSvcSubKey : MICROSOFT_SPEECH_API_KEY,
    voiceName : "pt-PT-RaquelNeural",
    avatarCharacter : "ama-avatar-1",
    avatarStyle : "casual-sitting",
    avatarBackgroundColor: "#F1F3F8FF",
    iceUrl : "turn:relay.communication.microsoft.com:3478",
  }