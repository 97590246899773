import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Autocomplete, TextField } from "@mui/material";

import { setSelectedValue } from "@/components/ChatHeader/chatSlice";
import { RootState } from "@/store";

import styles from "./index.module.css";

import AnswerBox from "@/components/Answer/AnswerBox";
import LANGUAGES from "@/languages";

import i18n from "i18next";
import { useTranslation } from "react-i18next";


type OptionType = {
    value: string;
    label: string;
} | null;

interface SelectLanguageProps {
    setLanguageApi: (language: string) => Promise<null>;
}

const CustomDropdownIndicator: React.FC<any> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_138_809)">
                <path
                    d="M12 16.5L4.5 8.99995L5.55 7.94995L12 14.4L18.45 7.94995L19.5 8.99995L12 16.5Z"
                    fill={"#212121"}
                    style={{
                        transition: "transform 0.45s ease, fill 0.3s ease",
                    }}
                />
            </g>
            <defs>
                <clipPath id="clip0_138_809">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};


const SelectLanguage: React.FC<SelectLanguageProps> = ({ setLanguageApi }) => {
    const dispatch = useDispatch();
    const selectedValueRedux = useSelector((state: RootState) => state.chat.selectedValue);
    const { t } = useTranslation();

    const handleChange = async (event: any, selectedOption: OptionType) => {
        dispatch(setSelectedValue(selectedOption));

        if (!import.meta.env.PROD) {
        }

        if (selectedOption) {
            i18n.changeLanguage(selectedOption.value);

            if (selectedOption.value === "ur-IN") {
                document.dir = "rtl";
            } else {
                document.dir = "ltr";
            }

            try {
                await setLanguageApi(selectedOption.value);
            } catch (error) {
                console.error("Failed to set language on the backend:", error);
            }
        }
    };

    return selectedValueRedux ? null : (
        <div className={styles.languages} style={{ zIndex: 1, position: "relative" }}>
            <AnswerBox
                sanitizedAnswerHtml="Olá, sou a sua assistente virtual. Escolha um idioma para falar comigo."
                isStreaming={false}
                showAvatar={true}
                disablePadding={false}
            />
            <div className={styles.selectText}>
                <span>Escolher idioma / Select Language</span>
            </div>
            <Autocomplete
                aria-label={t("settings.acessability.open")}
                popupIcon={<CustomDropdownIndicator />}
                options={LANGUAGES}
                getOptionLabel={(option) => option.label}
                onChange={handleChange}
                disablePortal // Prevent portal rendering
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Português (PT) / Portugal"
                        variant="outlined"
                        sx={{
                            // border: "1px solid #2b363c",
                            marginTop: "8px",
                            marginLeft: "43px",
                            width: "89%",
                            zIndex: 10,
                            backgroundColor: "white",
                        }}
                    />
                )}
                renderOption={(props, option, { inputValue, index }) => {
                    const { key, ...optionProps } = props;
                    const isLast = index === LANGUAGES.length - 1;
                    return (
                        <div
                            key={key}
                            style={{
                                borderTop: "1px solid #2b363c",
                                borderLeft: "1px solid #2b363c",
                                borderRight: "1px solid #2b363c",
                                borderBottom: isLast ? "1px solid #2b363c" : 'none',
                                padding: '16px 12px',
                                cursor: 'pointer',
                                color: 'black'
                            }}
                            {...optionProps}
                        >
                            {option.label}
                        </div>
                    );
                }}
                disableClearable
                isOptionEqualToValue={(option, value) => option.value === value.value}
                sx={{
                    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'black !important',
                    },
                    "& .MuiOutlinedInput-root": {
                        borderColor: "transparent",
                        borderRadius: "2px",
                        color: "#2B363C",
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2B363C',
                        },
                        '&:hover': {
                        color: "#034AD8",
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#034AD8 !important',
                        },

                    },
                }}
            />
        </div>
    );
};

export default SelectLanguage;
