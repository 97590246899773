import { createSlice } from "@reduxjs/toolkit";

export type OptionType = {
    value: string;
    label: string;
} | null;

interface ChatState {
    isOpen: boolean;
    isMini: boolean;
    isClose: boolean;
    isFullScreen: boolean;
    selectedValue: OptionType;
}

const initialState: ChatState = {
    isOpen: false,
    isMini: false,
    isClose: true,
    isFullScreen: false,
    selectedValue: null
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        toggleChat: state => {
            state.isOpen = true;
            state.isMini = false;
            state.isClose = false;
        },
        closeChat: state => {
            state.isOpen = false;
            state.isMini = false;
            state.isClose = true;
            state.selectedValue = null;
        },
        miniChat: state => {
            state.isOpen = false;
            state.isMini = true;
            state.isClose = false;
        },
        toggleSize: state => {
            state.isFullScreen = !state.isFullScreen;
        },
        setSelectedValue: (state, action) => {
            state.selectedValue = action.payload;
        }
    }
});

export const { toggleChat, closeChat, miniChat, setSelectedValue, toggleSize } = chatSlice.actions;
export default chatSlice.reducer;
