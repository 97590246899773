import React from "react";
import styles from "./Answer.module.css";
import avatar from "@/assets/avatar.png";
import { useTranslation } from "react-i18next";
import spin from "@/assets/spin.svg";

interface AnswerBoxProps {
    sanitizedAnswerHtml: string;
    showAvatar: boolean;
    isStreaming: boolean;
    error?: string;
    onRetry?: () => void;
    disablePadding: boolean;
}

const AnswerBox: React.FC<AnswerBoxProps> = ({ sanitizedAnswerHtml, showAvatar, isStreaming, disablePadding, error = "", onRetry }) => {
    const { t } = useTranslation();

    const handleRetry = () => {
        if (onRetry) {
            onRetry();
        }
    };

    return (
        <>
            <div className={`${styles.box} ${disablePadding ? styles.noPadding : ""}`}>
                <img className={`${styles.avatar} ${showAvatar && styles.showAvatar}`} src={avatar} alt="Avatar" />{" "}
                {isStreaming && !error && <p className={styles.answerText}>{t("settings.typing")}</p>}
                {!isStreaming && !error && (
                    <span className={`${styles.text} ${!showAvatar && styles.hiddenAvatar}`} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></span>
                )}
                {error && <span className={`${styles.text} ${!showAvatar && styles.hiddenAvatar}`}>{error}</span>}
            </div>
            {error && (
                <button onClick={handleRetry} className={styles.retryButton} aria-label={t("retry")}>
                    <img src={spin} alt={t("retry")} />
                </button>
            )}
        </>
    );
};

export default AnswerBox;
