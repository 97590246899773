import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedValue } from "@/components/ChatHeader/chatSlice";
import { RootState } from "@/store";
import { setLanguageApi } from "@/api";
import LANGUAGES from "@/languages";
import i18n from "i18next";

import { useTranslation } from "react-i18next";

interface ChangeLanguageProps {
    cleanChat: () => void;
}

const CustomDropdownIndicator: React.FC<DropdownIndicatorProps<any>> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_138_809)">
                <path
                    d="M12 16.5L4.5 8.99995L5.55 7.94995L12 14.4L18.45 7.94995L19.5 8.99995L12 16.5Z"
                    fill={"#212121"}
                    style={{
                        transition: "transform 0.45s ease, fill 0.3s ease"
                    }}
                />
            </g>
            <defs>
                <clipPath id="clip0_138_809">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const ChangeLanguage: React.FC<ChangeLanguageProps> = () => {
    const dispatch = useDispatch();
    const selectedValueRedux = useSelector((state: RootState) => state.chat.selectedValue);

    const { t } = useTranslation();

    const selectedOption = LANGUAGES.find(opt => opt.value === selectedValueRedux?.value) || null;

    const handleChange = async (event: React.SyntheticEvent, newValue: { value: string; label: string } | null) => {
        dispatch(setSelectedValue(newValue));

        if (newValue) {
            i18n.changeLanguage(newValue.value);

            try {
                await setLanguageApi(newValue.value);
            } catch (error) {
                console.error("Failed to set language on the backend:", error);
            }
        }
    };

    return (
        <Autocomplete
            aria-label={t("settings.acessability.open")}
            disablePortal
            popupIcon={<CustomDropdownIndicator />}
            options={LANGUAGES}
            getOptionLabel={option => option.label}
            value={selectedOption ? { label: selectedOption.label.split(" ")[0], value: selectedOption.value } : selectedOption}
            onChange={handleChange}
            disableClearable
            selectOnFocus={false}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    autoFocus
                    sx={{
                        width: "185px"
                    }}
                />
            )}
            renderOption={(props, option, { inputValue, index }) => {
                const { key, ...optionProps } = props;
                const isLast = index === LANGUAGES.length - 1;
                return (
                    <div
                        key={key}
                        style={{
                            borderTop: "1px solid #2b363c",
                            borderLeft: "1px solid #2b363c",
                            borderRight: "1px solid #2b363c",
                            borderBottom: isLast ? "1px solid #2b363c" : "none",
                            padding: "16px 12px",
                            cursor: "pointer",
                            color: selectedOption && selectedOption.value === option.value ? "#034AD8" : "black" // Change text color of selected option
                        }}
                        {...optionProps}
                    >
                        {option.label}
                    </div>
                );
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                    borderRadius: "0",
                    color: "black",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent"
                    },
                    ".MuiInputBase-input": {
                        textAlign: "right",
                        borderBottom: "2px solid transparent"
                    },
                    "&:hover .MuiInputBase-input": {
                        // Decide on which to add
                        // borderBottom: "2px solid #2b363c",
                        // color: "blue"
                    }
                }
            }}
        />
    );
};

export default ChangeLanguage;
