import React from "react";

export const Minimize: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="56" viewBox="0 0 32 32" fill="none">
            <g clipPath="url(#clip0_138_1372)">
                <path d="M26.3238 16.9899V15.01H16.9899L17 15L15.0101 15.01H5.67624V16.9899H15.0101L17 17L16.9899 16.9899H26.3238Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_138_1372">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const Close: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="56" viewBox="0 0 32 32" fill="none">
            <g clipPath="url(#clip0_138_1381)">
                <path d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4L14.6 16L8 22.6L9.4 24L16 17.4L22.6 24L24 22.6L17.4 16L24 9.4Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_138_1381">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const FullScreen: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M13.5 1H19V6.5M6.5 1H1V6.5M1 13.5V19H6.5M13.5 19H19V13.5" stroke="white" strokeWidth="2" />
        </svg>
    );
};
