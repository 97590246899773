import styles from "./index.module.css";
import React from "react";

export const SoundWaves: React.FC = () => {
    const barsCount = 10;

    return (
        <div className={styles.soundIcon}>
            <div className={styles.soundWave}>
                {Array.from({ length: barsCount }).map((_, index) => (
                    <i key={index} className={styles.bar}></i>
                ))}
            </div>
        </div>
    );
};
