import clear from "@/assets/clear.svg";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    const { t } = useTranslation();

    return (
        <button className={`${styles.button} ${className ?? ""} ${disabled && styles.disabled}`} onClick={onClick} aria-label={t("settings.acessability.clean")}>
            <img src={clear} alt={t("settings.clean")} />
            {t("settings.clean")}
        </button>
    );
};
