import i18n from "@/i18n/config";
import showdown from "showdown";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
};

const URL_REGEX = /(?<!\]\()(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)\b)/g;
const MARKDOWN_LINK_REGEX = /\[([^\]]+)]\((\S+?)\)/g;
const FOLLOWUP_QUESTION_REGEX = /<<([^>]+)>>/g;
const TRIMMING_STRING = "######";
const PHONE_NUMBER_REGEX = /(\d{3})\s(\d{3})\s(\d{3})/g;

const formatPhoneNumbers = (str: string): string => {
    return str.replace(PHONE_NUMBER_REGEX, "$1-$2-$3");
};

const replaceHyperlinks = (str: string): string => {
    return str.replace(URL_REGEX, '<a target="_blank" href="$1">$1</a>').replace(MARKDOWN_LINK_REGEX, '<a target="_blank" href="$2">$1</a>');
};

const extractFollowUpQuestions = (answer: string, followupQuestions: string[]): string => {
    return answer.replace(FOLLOWUP_QUESTION_REGEX, (_, content) => {
        followupQuestions.push(content);
        return "";
    });
};

export function parseAnswerToHtml(answer: string): HtmlParsedAnswer {
    const followupQuestions: string[] = [];
    const mdConverter = new showdown.Converter();
    let parsedAnswer = formatPhoneNumbers(answer);

    parsedAnswer = replaceHyperlinks(parsedAnswer);
    parsedAnswer = extractFollowUpQuestions(parsedAnswer, followupQuestions);

    if (parsedAnswer.includes(TRIMMING_STRING)) {
        parsedAnswer = parsedAnswer.split(TRIMMING_STRING)[0].trim();
    }

    parsedAnswer = mdConverter.makeHtml(parsedAnswer);

    // Correction of MakeHtml built in CSS
    parsedAnswer = parsedAnswer.replace(/<p>/g, '<p style="margin-top: -4px; margin-bottom: -4px;">');

    return {
        answerHtml: parsedAnswer,
        citations: [],
        followupQuestions
    };
}

export function parseAnswerToText(answer: string): string {
    if (answer.includes(TRIMMING_STRING)) {
        answer = answer.split(TRIMMING_STRING)[0];
    }

    let modifiedAnswer = formatPhoneNumbers(answer);

    modifiedAnswer = modifiedAnswer
        .replace(/Claro! |<<[^>]+>>|\((\S*?)\)|[#*]/g, "")
        .replace(/\[([^\]]+)]/g, i18n.t("settings.link"))
        .replace(
            new RegExp(`(?:\\s*-\\s*)?(${i18n.t("settings.link").replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&")})(\\s*-\\s*\\1)+`, "g"),
            i18n.t("settings.link_plural")
        )
        .trim();

    return modifiedAnswer;
}
