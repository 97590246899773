import {
    SpeechConfig,
    AudioConfig,
    AudioOutputStream,
    SpeechRecognizer,
    SpeechSynthesizer,
    SpeechSynthesisResult,
    SpeechRecognitionResult
} from "microsoft-cognitiveservices-speech-sdk";

const AZURE_SPEECH_KEY = import.meta.env.VITE_AZURE_SPEECH_KEY;
const AZURE_SPEECH_REGION = import.meta.env.VITE_AZURE_SPEECH_REGION;

const DEFAULT_LANGUAGE = "pt-PT";
export const VOICE_MAPPING: Readonly<Record<string, string>> = {
    "pt-PT": "pt-PT-RaquelNeural",
    "pt-BR": "pt-BR-FranciscaNeural",
    "en-US": "en-US-JennyNeural",
    "hi-IN": "hi-IN-SwaraNeural",
    "ar-AE": "ar-AE-FatimaNeural",
    "bn-BD": "bn-BD-NabanitaNeural",
    "zh-CN": "zh-CN-XiaoxiaoNeural",
    "fr-FR": "fr-FR-DeniseNeural",
    "ne-NP": "ne-NP-HemkalaNeural",
    "ro-RO": "ro-RO-AlinaNeural",
    "ru-RU": "ru-RU-SvetlanaNeural",
    "uk-UA": "uk-UA-PolinaNeural",
    "es-ES": "es-ES-ElviraNeural",
    "it-IT": "it-IT-ElsaNeural",
    "de-DE": "de-DE-KatjaNeural",
    "bn-IN": "bn-IN-TanishaaNeural",
    "ur-IN": "ur-IN-GulNeural"
};

/**
 * Adapter for Azure Speech Services.
 *
 * Any functionality that requires interaction with Azure Speech Services should
 * be implemented here.
 */
export class AzureSpeechServicesAdapter {

    private _synthesizer: SpeechSynthesizer;
    private _recognizer: SpeechRecognizer;
    private _selectedLanguage: string;

    constructor(selectedLanguage: string | null) {
        this._selectedLanguage = selectedLanguage || DEFAULT_LANGUAGE;
        this._synthesizer = this.configureSynthesizer();
        this._recognizer = this.configureRecognizer();
    }

    private createBaseSpeechConfig(): SpeechConfig {
        if (!AZURE_SPEECH_KEY || !AZURE_SPEECH_REGION) {
            throw new Error("Azure speech key or region is not set.");
        }
        return SpeechConfig.fromSubscription(AZURE_SPEECH_KEY, AZURE_SPEECH_REGION);
    }

    private configureSynthesizer(): SpeechSynthesizer {
        const speechConfig = this.createBaseSpeechConfig();
        speechConfig.speechSynthesisVoiceName = VOICE_MAPPING[this._selectedLanguage] || DEFAULT_LANGUAGE;
        // speechConfig.endpointId = AZURE_SPEECH_ENDPOINT_ID;
        const audioStream = AudioOutputStream.createPullStream();
        const audioConfig = AudioConfig.fromStreamOutput(audioStream);
        return new SpeechSynthesizer(speechConfig, audioConfig);
    }

    private configureRecognizer(): SpeechRecognizer {
        const speechConfig = this.createBaseSpeechConfig();
        speechConfig.speechRecognitionLanguage = this._selectedLanguage;
        return new SpeechRecognizer(speechConfig);
    }

    /**
     * Synthesizes speech from the specified text.
     * @param text - The text to synthesize.
     * @returns A Promise that resolves with the SpeechSynthesisResult when speech synthesis is complete.
     */
    public async synthesizeText(text: string): Promise<SpeechSynthesisResult> {
        return new Promise((resolve, reject) => {
            this._synthesizer.speakTextAsync(
                text,
                (result: SpeechSynthesisResult) => {
                    this._synthesizer.close();
                    resolve(result);
                },
                (error: string) => {
                    this._synthesizer.close();
                    reject(new Error(error));
                }
            );
        });
    }

    /**
     * Starts speech recognition.
     * @param cb - The callback to invoke when a recognition result is received.
     * @param err - The callback to invoke when an error occurs.
     */
    public recognizeOnce(
        cb?: (e: SpeechRecognitionResult) => void,
        err?: (e: string) => void
    ): void {
        this._recognizer.recognizeOnceAsync(
            (result: SpeechRecognitionResult) => {
                this._recognizer.close();
                if (cb) cb(result);
            },
            (error: string) => {
                this._recognizer.close();
                if (err) err(error);
            }
        );
    }

    get selectedLanguage(): string {
        return this._selectedLanguage;
    }

    set selectedLanguage(language: string) {
        this._selectedLanguage = language;
        this._synthesizer = this.configureSynthesizer();
        this._recognizer = this.configureRecognizer();
    }
}
