import React, { createContext, useState, useContext } from "react";
import { TabNames } from "../TabChat/index"

export type TabContextType = {
    activeTab: TabNames;
    setActiveTab: (tab: TabNames) => void;
};

const TabContext = createContext<TabContextType | undefined>(undefined);

export const TabProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeTab, setActiveTab] = useState<TabNames>(TabNames.CHAT);
    return (
        <TabContext.Provider value={{ activeTab, setActiveTab }}>
            {children}
        </TabContext.Provider>
    );
};

export const useTabContext = (): TabContextType => {
    const context = useContext(TabContext);
    if (!context) {
        throw new Error("useTabContext must be used within a TabProvider");
    }
    return context;
};