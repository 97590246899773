export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export const enum InteractionType {
    avatarAudioInput = "avatar",
    chatRegular = "manual"
}

export type LikeRequest = {
    conversation_id: string | null;
    iteration_id: string;
    like: boolean;
    reason: string;
};

export type AskResponse = {
    chat_message: string; // answer
    conversation_id: string;
    user_message: string;
    message_date: string;
    email: string;
    frustration_history: boolean;
    name: string;
    phone: string;
    state: string;
};

export type ChatRequest = {
    user_message: string;
    conversation_id?: string;
    language?: string;
    input_type?: string;
};

export type ChatTimer = {
    backend: number;
    avatarTTS: number;
    avatar: number;
};
