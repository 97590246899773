import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { avatarAppConfig } from "./config";

const cogSvcRegion = avatarAppConfig.cogSvcRegion;
const cogSvcSubKey = avatarAppConfig.cogSvcSubKey;
const avatarCharacter = avatarAppConfig.avatarCharacter;
const avatarBackgroundColor = avatarAppConfig.avatarBackgroundColor;

export const createWebRTCConnection = (iceServerUrl, iceServerUsername, iceServerCredential) => {
    var peerConnection = new RTCPeerConnection({
        iceServers: [
            {
                urls: [iceServerUrl],
                username: iceServerUsername,
                credential: iceServerCredential
            }
        ]
    });

    return peerConnection;
};

export const createAvatarSynthesizer = (iceUsername, iceCredential, voiceName) => {
    const speechSynthesisConfig = SpeechSDK.SpeechConfig.fromSubscription(cogSvcSubKey, cogSvcRegion);

    speechSynthesisConfig.speechSynthesisVoiceName = voiceName;

    const videoFormat = new SpeechSDK.AvatarVideoFormat();
    let videoCropTopLeftX = 600;
    let videoCropBottomRightX = 1320;
    videoFormat.setCropRange(new SpeechSDK.Coordinate(videoCropTopLeftX, 0), new SpeechSDK.Coordinate(videoCropBottomRightX, 750));

    const talkingAvatarCharacter = avatarCharacter;

    const avatarConfig = new SpeechSDK.AvatarConfig(talkingAvatarCharacter, null, videoFormat);
    avatarConfig.backgroundColor = avatarBackgroundColor;
    avatarConfig.customized = true;

    avatarConfig.remoteIceServers = [
        {
            urls: ["turn:relay.communication.microsoft.com:3478"],
            username: iceUsername,
            credential: iceCredential
        }
    ];

    let avatarSynthesizer = new SpeechSDK.AvatarSynthesizer(speechSynthesisConfig, avatarConfig);

    return avatarSynthesizer;
};
