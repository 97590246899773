import { createSlice } from "@reduxjs/toolkit";

interface ChatState {
    isAvatarVisible: boolean;
    isAvatarOn: boolean;
    isSoundOn: boolean;
    isLoading: boolean;
    talkState: string;
    isStreaming: boolean;
}

const initialState: ChatState = {
    isAvatarVisible: false,
    isAvatarOn: false,
    isSoundOn: false,
    talkState: "loading",
    isLoading: false,
    isStreaming: false
};

const chatValues = createSlice({
    name: "values",
    initialState,
    reducers: {
        isAvatarVisible: (state, action) => {
            state.isAvatarVisible = action.payload;
            if(state.isAvatarVisible){
                state.isSoundOn = true
            }
            state.isAvatarOn = action.payload;
        },
        toggleAvatar: state => {
            state.isAvatarOn = !state.isAvatarOn;
        },
        toggleAudio: state => {
            state.isSoundOn = !state.isSoundOn;
        },
        setSoundOff: (state, action) => {
            state.isSoundOn = action.payload;
        },
        hasTalkState: (state, action) => {
            state.talkState = action.payload;
        },
        loadingStatus: (state, action) => {
            state.isLoading = action.payload;
        },
        answerStreaming: (state, action) => {
            state.isStreaming = action.payload;
        }
    }
});

export const { isAvatarVisible, toggleAvatar, toggleAudio, setSoundOff, hasTalkState, loadingStatus, answerStreaming } = chatValues.actions;
export default chatValues.reducer;
