import React, { useState, useEffect } from "react";

import send from "@/assets/send.svg";
import styles from "./index.module.css";
import { AudioButtonSimple } from "../AudioButton/AudioButtonSimple";
import { TabNames } from "../TabChat/index"

import { useTranslation } from "react-i18next";


interface Props {
    question: string;
    onSend: (question: string, questionWAudio: boolean) => void;
    disabled: boolean;
    clearOnSend?: boolean;
    isRecording?: boolean;
    setIsRecording: (state: boolean) => void;
    setQuestion: (state: string) => void;
    activeTab: TabNames;
    isChatPage: boolean;
}

const isRtlLanguage = (languageCode: string) => {
    return ['ar', 'he', 'ur-IN'].includes(languageCode);
};

export const QuestionInput: React.FC<Props> = ({ question, onSend, disabled, activeTab, clearOnSend = false, isRecording = false, setIsRecording, setQuestion }) => {
    const [questionWAudio, setQuestionWAudio] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const [isRtl, setIsRtl] = useState(false);

    useEffect(() => {
        setIsRtl(isRtlLanguage(i18n.language));
    }, [i18n.language]);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question, questionWAudio);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = ev.target.value;

        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const setAudioQuestion = (text: string) => {
        setQuestionWAudio(true);
        setQuestion(text);
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <div className={styles.wrapper} dir={isRtl ? "rtl" : "ltr"}>
            <div className={styles.container}>
                <input
                    className={styles.input}
                    placeholder={t("settings.placeholder")}
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    disabled={disabled}
                    dir={isRtl ? "rtl" : "ltr"} // Apply RTL direction if needed
                    aria-label={t("settings.acessability.text_box")}
                />
                <div className={styles.audioButtonWrapper}>
                <AudioButtonSimple 
                    isChatPage={activeTab === TabNames.CHAT}
                    isRecording={isRecording} 
                    setIsRecording={setIsRecording} 
                    setQuestion={setAudioQuestion} 
                />
                </div>
                <button className={`${styles.send} ${sendQuestionDisabled && styles.sendDisabled}`} aria-label={t("settings.send")} onClick={sendQuestion} tabIndex={-1}>
                    <img src={send} alt={t("settings.send")} />
                </button>
            </div>
        </div>
    );
};
